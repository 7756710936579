import React, { useState,useEffect } from "react";
import {Modal} from 'react-bootstrap'; 
import Button from '@mui/material/Button'; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './popup_modals.css';
import { FaChalkboardTeacher } from 'react-icons/fa';
import dateFormat, { masks } from "dateformat";
import { newLesson } from "./db";
import { addDays } from "./time"
import * as Yup from 'yup';
import '../Components/forms.css'; 
import SelectTime from "./select_time";
import SelectLessonsNum from "./select_lessons_num";
import ReactLoading from 'react-loading';


const loginSchema = Yup.object().shape({ 

    subject: Yup.string()
        .min(5, 'Subject must be at least 5 characters')
        .max(40, 'Subject must be at most 40 characters')
        .required('Subject is required'),
    
    time: Yup.number()
        .min(1,'Time is required')        
        .required('Time is required'),

    num: Yup.number()
        .min(1,'Lessons number is required')        
        .required('Lessons number is required'),

    date: Yup.date()
        .min(new Date(), "Date must be in the future")
        .required("Date is required")
        .typeError('Please enter a valid date'),
              
});

const NewLesson=(props)=>{ 
    
    const [errors, setErrors] = useState({});
    const [show,setShow]=useState(false);
    const [fire,setFire]=useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState('ZOOM'); 
      
    const [lessonDtls, setLessonDtls] = useState({        
        time:0,
        start:'',
        num:0,
        subject:'',       
        sms:false,
        email:false,
        date:addDays(new Date(), 1),
        maxNum:0
    });

//-------------------------------------------------------------------------------------------

    useEffect(()=>{

        if(!props.link)
            setShow(true);
         
    },[]);
//------------------------------------------------------------------------------------------- 

    const alert = (msg,status) => {

        closeNewLesson()
        props.alert(msg,status)
    }
//------------------------------------------------------------------------------------------- 

    const getTime=(time)=>{     
        setLessonDtls(prevState => ({
            ...prevState,
            ['time']:time,           
        }))      
    }
//------------------------------------------------------------------------------------------- 

    const getMaxNum=(maxNum)=>{
       
        setLessonDtls(prevState => ({
            ...prevState,            
            ['maxNum']:maxNum,           
        }))      
    }
//------------------------------------------------------------------------------------------- 

    const getStartTime=(start)=>{
        setLessonDtls(prevState => ({
            ...prevState,        
            ['start']:start
        }))
    }
//------------------------------------------------------------------------------------------- 

    const getLessonsNum=(num)=>{
        setLessonDtls(prevState => ({
            ...prevState,        
            ['num']:num
        }))
    }
//------------------------------------------------------------------------------------------- 

    const handleChange = e => {
        const { name, value } = e.target;
        setLessonDtls(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
//------------------------------------------------------------------------------------------- 

    const handlePlatformChange = e => {
        setSelectedPlatform(e.target.value);
        
    };
//------------------------------------------------------------------------------------------- 

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const HAMMER_TIME_FORMAT = 'yyyy-mm-dd';

        setFire(true);

        try {
            await loginSchema.validate(lessonDtls, { abortEarly: false });

            const responseData = await newLesson(
                props.code,
                props.tchId,
                props.price,
                lessonDtls.subject,
                lessonDtls.num,
                lessonDtls.time,
                lessonDtls.start,
                lessonDtls.sms,
                lessonDtls.email,
                selectedPlatform,
                dateFormat(lessonDtls.date, HAMMER_TIME_FORMAT)
            );

            handleResponseData(responseData);

        } catch (error) {
            handleError(error);
        } finally {
            setFire(false);
        }
    };
//------------------------------------------------------------------------------------------- 

    const handleResponseData = (responseData) => {
        if (responseData) {
            if (responseData.code === 1) {
                alert(responseData.message,'SUCCESS');
                props.refreshPage();
            } else if (responseData.code === 0 && responseData.redirectUrl) {

                const popupWindow = window.open(responseData.redirectUrl, '_blank','width=550,height=600,left=359,top=100');

                const checkPopupClosed = setInterval(() => {
                    if (popupWindow.closed) {
                        clearInterval(checkPopupClosed); 
                        closeNewLesson(); 
                        props.refreshPage();
                    }
                }, 500); 
                
            } else {
                alert(responseData.message,'ERROR');
            }
        }
    };
//------------------------------------------------------------------------------------------- 

    const handleError = (error) => {
        if (error.inner) {
            const formattedErrors = error.inner.reduce((acc, { path, message }) => {
                acc[path] = message;
                return acc;
            }, {});
            setErrors(formattedErrors);
        } else {
            alert(error.message,'ERROR');
        }
    };
//------------------------------------------------------------------------------------------- 

    const openNewLesson = () => { 
        setShow(true);
    }
//------------------------------------------------------------------------------------------- 

    const closeNewLesson = () => { 

        setShow(false);

        setLessonDtls({
            time:0,
            start:'',
            num:0,
            subject:"",          
            sms:false,
            email:false,
            date:addDays(new Date(), 1),
            maxNum:0
        })  
         

        setErrors({})

        if(!props.link)
            props.resetMenu(0);
    }
//------------------------------------------------------------------------------------------- 

    return (  
        <div>        
            {props.link?
                <>  
                    {props.enabled && props.status!=="CLOSED"?                        
                        <Button variant="contained" color="success" size="small" onClick={openNewLesson}>יצירת מפגש</Button>:                    
                        <Button variant="contained" color="success" size="small" disabled>יצירת מפגש</Button>                       
                    }
                </>
                :null
            }           
                        
            <Modal 
              show={show} 
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"            
             >  

                <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
                    <div className="d-flex gap-2 align-items-center">
                        <div><FaChalkboardTeacher size={30} /></div>
                        <div className="mt-1"><h5>מפגש חדש</h5></div>
                    </div> 
                    <button
                        type="button"
                        className="custom-modal-close"
                        aria-label="Close"
                        onClick={closeNewLesson}
                    >
                        &times;  {/* Close "X" icon */}
                    </button>                                             
                </Modal.Header>  
                
                <form onSubmit={handleSubmit}>
                    <Modal.Body> 
                        <div className="container">
                            {fire?
                                <div 
                                    className="position-absolute top-50 start-50 translate-middle-x" 
                                    style={{zIndex: "1",top:"300",left:'300'}}
                                >
                                    <ReactLoading 
                                        type="spin" 
                                        color="rgb(53, 126, 221)" 
                                        height={70} 
                                        width={70}
                                    />
                                </div>
                                :
                                null
                            }
                            <div class="d-flex flex-wrap gap-4 mt-4">
                                                                                                
                                <div className="label-100-wrap">                    
                                    <label className="form-label label-100" for="date">תאריך</label>
                                    <div name="date" dir="ltr">              
                                        <DatePicker 
                                            wrapperClassName="date-picker"  
                                            selected={lessonDtls.date} 
                                            onChange={(date)=>{
                                                setLessonDtls(prevState => ({
                                                    ...prevState,
                                                    date: date,
                                                    time:0, 
                                                    num:1                                               
                                                }));
                                            }} 
                                            popperPlacement="bottom-end"
                                            minDate={addDays(new Date(), 1)}
                                            dateFormat="yyyy-MM-dd"  
                                        />                      
                                    </div>
                                    {errors.date && <p className="error">{errors.date}</p>}
                                </div>

                                <SelectTime 
                                    editing={false}
                                    tchId={props.tchId}
                                    time={lessonDtls.time} 
                                    date={lessonDtls.date} 
                                    error={errors.time}
                                    setTime={getTime}
                                    setLessonsNum={getLessonsNum}
                                    setMaxNum={getMaxNum}
                                    setStartTime={getStartTime}
                                    alert={alert}
                                    close={closeNewLesson}
                                />

                                <SelectLessonsNum                                 
                                    maxNum={lessonDtls.maxNum}
                                    num={lessonDtls.num}
                                    error={errors.num && <>{errors.num}</>}
                                    setLessonsNum={getLessonsNum}
                                />
                                                                                                                    
                            </div> 
                            
                            <div className="label-100-wrap mt-4">                
                                <label className="form-label label-100" for="subject">נושא</label>
                                <div>
                                    <input  
                                        type="text" 
                                        className={`form-control ${errors.subject? 'invalid' : ''}`} 
                                        name="subject" 
                                        value={lessonDtls.subject} 
                                        onChange={handleChange} 
                                        placeholder="נושא שיעור"
                                    />
                                    {errors.subject && <p className="error">{errors.subject}</p>}
                                </div>   
                            </div> 

                            <div className="mb-2 mt-4 d-flex flex-column gap-2">
                                <label>מפגש מקוון בעזרת:</label>

                                <div className="d-flex flex-row gap-4">
                                    <div className="form-check form-check-inline">
                                        <input 
                                            className="form-check-input"
                                            type="radio" 
                                            id="zoomOption"
                                            value="ZOOM" 
                                            checked={selectedPlatform === 'ZOOM'} 
                                            onChange={handlePlatformChange}
                                        />
                                        <label className="form-check-label" htmlFor="zoomOption">ZOOM</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input 
                                            className="form-check-input"
                                            type="radio" 
                                            id="googleMeetOption"
                                            value="GOOGLE_MEET" 
                                            checked={selectedPlatform === 'GOOGLE_MEET'} 
                                            onChange={handlePlatformChange}
                                            disabled
                                        />
                                        <label className="form-check-label" htmlFor="googleMeetOption">GOOGLE MEET</label>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mt-4">
                                <div class="form-check" > 
                                    <input 
                                        class="form-check-input" 
                                        type="checkbox" 
                                        name="sms" 
                                        checked={lessonDtls.sms}
                                        onClick={()=>{setLessonDtls(prevState => ({
                                            ...prevState,
                                            sms: !prevState.sms
                                        }));
                                        }}                                     
                                    />
                                    <label class="form-check-label" for="sms">
                                        שלח הודעת SMS למשתתפים בקורס.
                                    </label>                                
                                </div>

                                <div class="form-check">                                                                 
                                    <input 
                                        class="form-check-input" 
                                        type="checkbox"                                       
                                        name="email" 
                                        checked={lessonDtls.email} 
                                        onClick={()=>{setLessonDtls(prevState => ({
                                            ...prevState,
                                            email: !prevState.email
                                        }));
                                        }}                                    
                                    />                                        
                                    <label class="form-check-label" for="email">
                                        שלח הודעת דוא"ל למשתתפים בקורס.
                                    </label>                              
                                </div>
                            </div>    
                        </div>
                        
                    </Modal.Body>  

                    <Modal.Footer>
                        <div className="d-flex gap-3 me-3 mb-2">                         
                            <Button 
                                variant="contained"  
                                color="error"  
                                size="small" 
                                onClick={closeNewLesson}
                                disabled={fire}
                            >
                                ביטול
                            </Button>  
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="success" 
                                size="small" 
                                disabled={fire}
                            >
                                שמירה
                            </Button>                         
                        </div>
                    </Modal.Footer> 
                </form>        
            </Modal> 
        </div>        
    )  
  } 
  
export default NewLesson;  
