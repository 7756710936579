import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const login = async (user, auth, email, password, f_name, l_name) => {
    try {            
        const response = await axios.get(`${baseUrl}/login.php`, {
            params: {
                user: user,
                auth: auth,
                email: email,
                password: password,
                f_name: f_name,
                l_name: l_name,
            }
        });

        if (response.data.status === 'success') {
            return [
                response.data.data.id,
                response.data.data.userId,
                response.data.data.name,
                response.data.data.photo,
                response.data.data.enabled,
                response.data.data.blocked
            ];
        } else if (response.data.status === 'error') {
                        
            throw new Error(response.data.message);
        }
    } catch(error) {
        throw new Error('Login Error: ' + error.message);
    }
} 
//-------------------------------------------------------------------------------------------

export const logout = async (userId) => {
    try { 
        
        const response = await axios.get(`${baseUrl}/logout.php`, {
            params: {
               userId:userId,
            }
        });

        if (response.data.status === 'success') {
            return true
        } else if (response.data.status === 'error') {                        
            throw new Error(response.data.message);
        }
    } catch(error) {
        throw new Error('Logout Error: ' + error.message);
    }
} 
//-------------------------------------------------------------------------------------------

export const updateExpirationTime = async(userId,time) => {
    try { 
        
        const response = await axios.get(`${baseUrl}/update_expiration_time.php`, {
            params: {
                userId: userId,
                time: time,
            }
        });

        if (response.data.status === 'success') {
            return true
        } else if (response.data.status === 'error') {                        
            throw new Error(response.data.message);
        }
    } catch(error) {
        throw new Error('Update Expiration Time Error: ' + error.message);
    }
} 
//-------------------------------------------------------------------------------------------

export const signup = async (user, auth, email, password, f_name, l_name) => {
    try {            
        const response = await axios.get(`${baseUrl}/signup.php`, {
            params: {
                user: user,
                auth: auth,
                email: email,
                password: password,
                f_name: f_name,
                l_name: l_name
            }
        });

        if (response.data.status === 'success') {
            return [
                response.data.data.id,
                response.data.data.userId,
                f_name+' '+l_name,
                response.data.data.enabled,
                response.data.data.blocked
            ]; 
        } else {
            throw new Error(response.data.message); 
        }
    } catch (error) {
        
        throw error;
    }
}
//-------------------------------------------------------------------------------------------

export const passwordRecovery = async (user, email) => {
    try {
        const response = await axios.get(`${baseUrl}/passwordRecovery.php`, {
            params: {
                user: user,
                email: email
            }
        });

        const data = response.data;

        // If the response is successful, return only the message
        if (data.status === 'success') {
            return data.message;
        } else {
            // If the status is not 'success', throw an error
            throw new Error(data.message);
        }

    } catch (error) {
        // Throw an error to the parent caller
        throw new Error(error.message || "An error occurred while retrieving the password.");
    }
};
//-------------------------------------------------------------------------------------------

export const newCourse = async (user, tchMaker, schMaker, tchId, subId, catId, sDate, price, name, mode) => {
    try {
        const response = await axios.get(`${baseUrl}/new_course.php`, {
            params: {
                mode: mode,
                price: price,
                user: user,
                tchMaker: tchMaker,
                schMaker: schMaker,
                tchId: tchId,
                name: name,
                subId: subId,
                catId: catId,
                sDate: sDate                                               
            }
        });

        const responseData = response.data;
        if (responseData.status === 'success') {
            return responseData.message; 
        } else {
            throw new Error(responseData.message); 
        }
    } catch (error) {
        throw new Error("Failed to create new course: " + (error.message || 'Unknown error occurred')); // Adds a prefix to axios-related errors
    }
}
//-------------------------------------------------------------------------------------------

export const updateCourse = async (user, tchId, subId, catId, sDate, price, name, code) => {
    try {
        const response = await axios.get(`${baseUrl}/update_course.php`, {
            params: {
                price: price,
                user: user,
                tchId: tchId,
                name: name,
                subId: subId,
                catId: catId,
                sDate: sDate,
                code: code                                            
            }
        });

        const responseData = response.data;
        if (responseData.status === 'success') {
            return responseData.message;
        } else {
            throw new Error(responseData.message);
        }
    } catch (error) {
        throw new Error("Failed to update course: " + (error.message || 'Unknown error occurred'));
    }
}

//-------------------------------------------------------------------------------------------
export const newLesson = async (code, tchId, price, sub, num, time, start, sms, email, platform, date) => {
    
    try {
        const response = await axios.get(`${baseUrl}/new_lesson.php`, {
            params: {
                code: code,
                tchId: tchId,
                price: price,
                sub: sub,                
                num: num,
                time: time,
                hour:start,
                sms:sms,
                email:email,
                platform:platform,
                date: date                                             
            }
        });
        
        return response.data;

    } catch (error) {
        throw new Error(error.message);    
    }
}
//-------------------------------------------------------------------------------------------

export const sendMessage = async (firstName,lastName,email,phone,message,subject) => {
             
    try
    {
        const response=await axios.get(`${baseUrl}/send_message.php`,{
            params: {
                firstName:firstName,
                lastName:lastName,
                email:email,
                phone:phone,
                message:message,
                subject:subject                                                            
            }
        })
        
        if(response.data.status==='success'){
            return(response.data.message)
        }
        else{
            throw new Error(response.data.message || 'An unknown error occurred');
        }
    }
    catch(error){
        throw new Error(error.message);   
    }
} 
//-------------------------------------------------------------------------------------------

export const updateUserDtls = async (name,fname,email,phone,aboutMe,userId) => {
             
    try
    {
        const response=await axios.get(`${baseUrl}/update_user_dtls.php`,{
            params: {
                name:name,
                fname:fname,
                email:email,
                phone:phone,
                aboutMe:aboutMe,
                userId:userId                                                             
            }
        })
                       
        if(response.data.status==='error'){

            throw new Error(response.data.message || 'Unknown error occurred')  
        } 
        
        return response.data
        
    }
    catch (error) {
        throw new Error(error.message);
    }
}
//-------------------------------------------------------------------------------------------

export const updateUserPassword = async (password,userId) => {
             
    try
    {
        const response=await axios.get(`${baseUrl}/update_user_password.php`,{
            params: {
                password:password,
                userId:userId                                                            
            }
        })
       
        return response.data
    }
    catch(error){
        alert(error)
        return -6
    }
}
//-------------------------------------------------------------------------------------------

export const pairUserPhoto = async (userId,file) => {
             
    try
    {
        const response=await axios.get(`${baseUrl}/pair_user_photo.php`,{
            params: {
                userId:userId,
                file:file                                                           
            }
        })
        
        return response.data.id
    }
    catch(error){
        alert(error)
        return -6
    }
}
//-------------------------------------------------------------------------------------------

    export const pairSubPhoto = async (subId,file) => {
                
        try
        {
            const response=await axios.get(`${baseUrl}/pair_subject_photo.php`,{
                params: {
                    subId:subId,
                    file:file                                                           
                }
            })
            
            return response.data.id
        }
        catch(error){
            alert(error)
            return -6
        }
    }
//-------------------------------------------------------------------------------------------

export const newCanvaTemplate = async (templateId,url) => {
                
    try
    {
        const response=await axios.get(`${baseUrl}/new_canva_template.php`,{
            params: {
                templateId:templateId,
                url:url                                                           
            }
        })

        if (response.data.status === 'error') {
            throw new Error(response.data.message || 'Failed to add new Canva template.');
        }

        return response.data.id;
        
    }
    catch(error){
        throw new Error(`Error adding Canva template: ${error.message}`);
    }
}
//-------------------------------------------------------------------------------------------

export const updatePrivateLessonRating = async (lessonId,tchId,value) => {
             
    try
    {
        const response=await axios.get(`${baseUrl}/update_private_lesson_rating.php`,{
            params: {
                lessonId:lessonId,
                tchId:tchId,
                value:value                                                            
            }
        })
       
        return response.data
    }
    catch(error){
        alert(error)
        return -6
    }
}
//-------------------------------------------------------------------------------------------

export const updateLesson = async (lessonId,sub,num,time,date) => {
             
    try
    {
        const response=await axios.get(`${baseUrl}/update_lesson_dtls.php`,{
            params: {
                lessonId:lessonId,
                sub:sub,                
                num:num,
                time:time,
                date:date                                                          
            }
        })
       
        return response.data.id
    }
    catch(error){
        alert(error)
        return -6
    }
}
//-------------------------------------------------------------------------------------------

export const updateGroupLessonRating = async (lessonId,stdId,tchId,value) => {
             
    try
    {
        const response=await axios.get(`${baseUrl}/update_group_lesson_rating.php`,{
            params: {
                lessonId:lessonId,
                tchId:tchId,
                stdId:stdId,
                value:value                                                            
            }
        })
       
        return response.data
    }
    catch(error){
        alert(error)
        return -6
    }
}
//-------------------------------------------------------------------------------------------

export const updateCourseSyllabus = async (code,url) => {
             
    try
    {
        const response=await axios.get(`${baseUrl}/update_course_syllabus.php`,{
            params: {
                code:code,
                url:url                                                            
            }
        })
       
        return response.data
    }
    catch(error){
        alert(error)
        return -6
    }
}
//-------------------------------------------------------------------------------------------

export const updateZoomLink = async (link,lessonId,mode,recording) => {
             
    try
    {
        const response=await axios.get(`${baseUrl}/update_zoom_link.php`,{
            params: {
                link:link,
                lessonId:lessonId,
                mode:mode,
                recording:recording                                                         
            }
        })
       
        return response.data
    }
    catch(error){
        alert(error)
        return -6
    }
}
//-------------------------------------------------------------------------------------------

export const updateLessonSubject = async (subject, lessonId, mode) => {
    try {
        const response = await axios.get(`${baseUrl}/update_lesson_subject.php`, {
            params: {
                subject: subject,
                lessonId: lessonId, 
                mode: mode                                                                        
            }
        });

        if (response.data.status === 'error') {
            throw new Error(response.data.message || 'An error occurred while updating the lesson subject');
        }

        return response.data;
    } catch (error) {
        
        throw new Error(`Failed to update lesson subject: ${error.message}`);
    }
}
//-------------------------------------------------------------------------------------------

export const updateMeetingStatus = async (lessonId, status) => { 
    try {
        const response = await axios.get(`${baseUrl}/update_meeting_status.php`, {
            params: {
                lessonId: lessonId,
                status: status                                         
            }
        });
        
        if (response.data.code < 0) {
            throw new Error(response.data.message || 'Failed to update meeting status');
        }

        return response.data;

    } catch (error) {
        
        throw new Error(`Error in updateMeetingStatus: ${error.message}`);
    }
}

//-------------------------------------------------------------------------------------------

export const updateMessageStatus = async (msgId,status) => {
             
    try
    {
        const response=await axios.get(`${baseUrl}/update_msg_status.php`,{
            params: {
                msgId:msgId,
                status:status                                                                         
            }
        })
       
        return response.data
    }
    catch(error){
        alert(error)
        return -6
    }
}
//-------------------------------------------------------------------------------------------

export const replyToMsg = async (msgId,content) => {
             
    try
    {
        const response=await axios.get(`${baseUrl}/update_msg_content.php`,{
            params: {
                msgId:msgId,
                content:content,                                                                         
            }
        })
       
        return response.data
    }
    catch(error){
        alert(error)
        return -6
    }
}
//-------------------------------------------------------------------------------------------

export const newZoomMeeting = async (lessonId, tchId, mode, topic, platform) => {
    try {
        const response = await axios.get(`${baseUrl}/zoom_meeting.php`, {
            params: {
                lessonId: lessonId,
                tchId: tchId,
                mode: mode,
                topic:topic,
                platform:platform
            }
        });

        const responseData=response.data;

        if(responseData.code<0)
        {
            throw new Error(responseData.message)
        }

        return response.data;

        
    } catch (error) {
        throw error;
    }
}
//-------------------------------------------------------------------------------------------

export const getAvailableMeetings = async (lessonId, mode) => {
    try {
        
        const response = await axios.get(`${baseUrl}/get_available_zoom_meetings.php`, {
            params: {
                lessonId: lessonId,
                mode: mode
            }
        });
       
        if (response.data.code && response.data.code > 0) {
            return {
                code: response.data.code,
                hostUrl: response.data.hostUrl,
                joinUrl: response.data.joinUrl,
                startTime: response.data.startTime,
                duration: response.data.duration,
                tch_done: response.data.tch_done,
            };
        } else {
            
            throw new Error(response.data.error || 'Unknown error');
        }
    } catch (error) {

        throw error;
    }
};
//-------------------------------------------------------------------------------------------

export const setTeacherZoomKeys = async (tchId, ACCOUNT_ID, CLIENT_ID, SECRET_KEY) => {
    try {
        const response = await axios.get(`${baseUrl}/update_zoom_keys.php`, {
            params: {
                tchId:tchId,
                ACCOUNT_ID:ACCOUNT_ID,
                CLIENT_ID:CLIENT_ID,
                SECRET_KEY:SECRET_KEY,
            }
        });

        if (response.data.code && response.data.code > 0) {
            return response.data;
        } else {
            throw new Error(response.data.message || "Error updating the record.");
        }

    } catch (error) {
        
        throw error; 
    }
};
//-------------------------------------------------------------------------------------------

export const setLessonDone = async (lessonId, user, mode, userId) => {
    
    try {
        const response = await axios.get(`${baseUrl}/set_lesson_done.php`, {
            params: {
                lessonId:lessonId,
                user:user,
                mode:mode,
                userId:userId,
            }
        });

        if (response.data.code && response.data.code > 0) {
            return response.data;
        } else {
            throw new Error(response.data.message || "Error updating the record.");
        }

    } catch (error) {
        
        throw error; 
    }
};

//-------------------------------------------------------------------------------------------

export const sendReceipt = async (receiptData, lessonsIdArr, userId, creditUsed) => {
    try {

        const response = await axios.get(`${baseUrl}/send_receipt.php`, {
            params: {
                receiptData: receiptData,
                lessonsIdArr:lessonsIdArr,
                userId:userId,
                creditUsed:creditUsed,
            }
        });
        
        if (response.data.status ==='success' ) {
            return(response.data.message);
        } else {
            throw new Error(response.data.message);
        }

    } catch (error) {
        
        throw error;
    }
}
//-------------------------------------------------------------------------------------------

export const newReceipt = async (stdId) => {
             
    try
    {
        const response=await axios.get(`${baseUrl}/new_receipt.php`,{
            params: {
                stdId:stdId,                                                                       
            }
        })

        if(response.data.status==='success')
            return response.data.receipt_number
        else
            throw new Error(response.data.error)

    }
    catch(error){
        throw error 
    }
}
//-------------------------------------------------------------------------------------------

export const cancelLesson = async (lessonId,mode) => {
    try {
        const response = await axios.get(`${baseUrl}/cancel_lesson.php`, {
            params: {
                lessonId: lessonId,
                mode:mode,
            }
        });


        if (response.data.status === 'success') {
           
            return  response.data.message;
        } else {
            
            throw new Error(response.data.message);
        }

    } catch (error) {

        const errorMessage = `Error cancelling lesson: ${error.response ? error.response.data.message : error.message}`;
        throw new Error(errorMessage);

    }
}
//-------------------------------------------------------------------------------------------

export const getStudentCredit = async (userId)=> {
    try {

      const response = await axios.get(`${baseUrl}/get_student_credit.php`,{
        params: {                        
            userId:userId,                                                                    
        }
      })
      
      if(response.data.status==='success'){      
        return response.data.credit 
      } else {
       
        throw new Error(response.data.message)
      }
    } 
    catch (error) {
     
      const errorMessage = `Error fetching student's credit: ${error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message ? error.message : 'Unknown error'}`;
      throw new Error(errorMessage);
      
    } 
  }
//-------------------------------------------------------------------------------------------

  export const getTimeFromServer = async ()=> {
    try {

      const response = await axios.get(`${baseUrl}/get_current_date_and_time.php`);
      
      const currenTimeInMillis=new Date( response.data.now).getTime();
      return currenTimeInMillis;
      
    } 
    catch (error) {
     
      alert(`Error fetching date and time from server: ${error.message}`);
      
    } 
  }
//-------------------------------------------------------------------------------------------

export const getContactDtls = async (userId)=> {
    
    try {

      const response = await axios.get(`${baseUrl}/get_contact_dtls.php`,{
        params: {                        
            userId:userId,                                                                    
        }
      })
      
      if(response.data.status==='success'){      
        return [response.data.email , response.data.mobile]
      } else {
        return ['doroos.online24@gmail.com' , '0506595605']
       // throw new Error(response.data.message)
      }
    } 
    catch (error) {
     
      throw new Error(error.message);
      
    } 
  }








