import React, { useState } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import PublishIcon from '@mui/icons-material/Publish';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { MdVpnKey } from "react-icons/md";
import SelectIcon from './select_icon';
import SetCoursePro from './set_course_schedule';
import AddSyllabus from './add_syllabus';
import StdsInCourseReport from './stds_in_course_report';
import MsgBox from './msg_box'; 
import axios from 'axios';
import CourseModal from './course_modal';
import GenerateSocialPost from './select_canva_template';

const baseUrl = process.env.REACT_APP_BASE_URL;

const msg_1="האם אתה בטוח שברצונך לפרסם את הקורס??";
const msg_2="האם אתה בטוח שברצונך לסגור את הקורס ולהעביר אותו לארכיון??";

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          width: '200px', // Set the width you need
        },
      },
    },
  },
});

const FontAwesomeSvgIcon = React.forwardRef((props, ref) => {
  const { icon } = props;

  const {
    icon: [width, height, , , svgPathData],
  } = icon;

  return (
    <SvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`}>
      {typeof svgPathData === 'string' ? (
        <path d={svgPathData} />
      ) : (
        /**
         * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
         * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
         * of a duotone icon. 40% is the default opacity.
         *
         * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
         */
        svgPathData.map((d, i) => (
          <path style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />
        ))
      )}
    </SvgIcon>
  );
});

FontAwesomeSvgIcon.propTypes = {
  icon: PropTypes.any.isRequired,
};

const CourseMenu=(props)=> {
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem,setSelectedItem]=useState(0);
  const open = Boolean(anchorEl);

    
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
//-------------------------------------------------------------------------------------------
  
  const handleCloseMenu = (number) => {
    setSelectedItem(number);
    setAnchorEl(null);    
  };
//-------------------------------------------------------------------------------------------
  
  const Close = () => {
    handleCloseMenu(0)
  }
//-------------------------------------------------------------------------------------------

  const onAction= async () => {
             
    let s="";

    if(props.status==="EDITING")
       s="OPENED"
    else if(props.status==="OPENED")
       s="CLOSED"

    try {
      
      const response = await axios.get(`${baseUrl}/update_course_status.php`,{
        params: {                        
          code:props.code,
          status:s                                                          
        }
      })
      
      if( response.data > 0 ){ 
        
        props.updateStatus(s,props.index)

        if(s==="CLOSED")         
          props.removeCourseFromList(props.code)        
      }
      else{                             
        props.alert("אופס ! משהו השתבש !!!",'ERROR')
      }
    } 
    catch (error) {

      props.alert(error.message,'ERROR') ;
      
    }      
  }
//-------------------------------------------------------------------------------------------


  return (

    <>
      { selectedItem===1?     
          <div>
            <CourseModal  
              link={false} 
              editing={true} 
              resetMenu={handleCloseMenu} 
              user={props.user} 
              code={props.code} m
              akerId={props.makerId} 
              updateCourseDtls={props.updateCourseDtls}
              alert={props.alert}
            />
          </div>
        :selectedItem===2?
          <div>
            <AddSyllabus 
              link={false} 
              resetMenu={handleCloseMenu} 
              code={props.code} 
              syllabus={props.syllabus} 
              updateSyllabus={props.updateSyllabus} 
              index={props.index}
              alert={props.alert}
            />
          </div>
        :selectedItem===3?
          <div>
            <SelectIcon 
              link={false} 
              resetMenu={handleCloseMenu} 
              code={props.code} 
              changeIcon={props.changeIcon}
              alert={props.alert}
            />
          </div>
        :selectedItem===4?
          <div>
            <SetCoursePro 
              link={false} 
              resetMenu={handleCloseMenu}  
              refreshSchedule={props.refreshSchedule} 
              code={props.xcode} 
              tchId={props.tchId}
              alert={props.alert}
            />
          </div>
        :selectedItem===5?
        <div>
          <StdsInCourseReport 
            link={false} 
            resetMenu={handleCloseMenu} 
            code={props.code} 
            alert={props.alert}
          />
        </div>
        :selectedItem===6?
        <div>
          <MsgBox 
            link={false} 
            close={Close} 
            onAction={onAction} 
            content={props.status==="EDITING"?msg_1:msg_2} 
          />
        </div>
         :selectedItem===7?
         <div>
           <GenerateSocialPost
             resetMenu={handleCloseMenu}
           />
         </div>
        :null 
      }
    
      <React.Fragment>
        <Box
          sx={{
            '& > :not(style)': {
              m: 0.5,
            },
          }}
        >      
          <IconButton  onClick={handleClick}>
            <MoreVertIcon fontSize='medium'/>
          </IconButton>      
        </Box>      
        <ThemeProvider theme={theme}>
          <Menu
            dir="ltr"
            anchorEl={anchorEl}
            id="account-menu"
            open={open}        
            onClose={() => handleCloseMenu(0)}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', 
                mt:1,        
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,                         
                  mr:10,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          >
            

            {
              (props.user === 'SCH' || (props.user === 'TCH' && props.sch_maker === null)) ? (
                <>
                  <MenuItem className='d-flex align-items-center justify-content-between' onClick={() => handleCloseMenu(1)}>
                    <div><EditRoundedIcon/></div>
                    <div>עריכה</div>                                                  
                  </MenuItem>

                  <MenuItem className='d-flex align-items-center justify-content-between' onClick={() => handleCloseMenu(2)}>
                    <div><PostAddIcon/></div>
                    <div>הוספת סילבוס</div>
                  </MenuItem>

                  <MenuItem  className='d-flex align-items-center justify-content-between' onClick={() => handleCloseMenu(3)}>
                    <div> <AddPhotoAlternateIcon/></div>
                    <div>בחירת תמונה</div>                    
                  </MenuItem>

                </>
              ) : null
            }
            
            {
              (!props.private && props.user === 'TCH') && (
                <MenuItem className='d-flex align-items-center justify-content-between' onClick={() => handleCloseMenu(4)}>
                  <div><WorkHistoryIcon/></div>
                  <div>מערכת שעות</div>
                </MenuItem>
              )
            }

            <MenuItem  className='d-flex align-items-center justify-content-between' onClick={() => handleCloseMenu(7)}>
              <div><PeopleOutlineIcon/></div>
              <div>?מי משתתף בקורס</div>
            </MenuItem>

            <MenuItem  className='d-flex align-items-center justify-content-between' onClick={() => handleCloseMenu(5)}>
              <div><PeopleOutlineIcon/></div>
              <div>?מי משתתף בקורס</div>
            </MenuItem>

            {
              (props.user === 'SCH' || (props.user === 'TCH' && props.sch_maker === null)) ? (
                <MenuItem  className='d-flex align-items-center justify-content-between' onClick={() => handleCloseMenu(6)}>
                  {props.status==="EDITING"?
                    <>
                      <div> <PublishIcon size={25}/></div>              
                      <div>פרסם קורס</div>
                    </>
                    :
                    <>
                      <div> <MdVpnKey size={25}/></div>
                      <div>סגור קורס</div> 
                    </>               
                  }
                </MenuItem>
              ) : null
            }

          </Menu>
        </ThemeProvider>

      </React.Fragment> 
    </>  
  );
}
export default  CourseMenu;
