
import React, { useState,useEffect } from "react";
import { FcApproval } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import ReactLoading from 'react-loading';
import { updateUserDtls } from "./db";
import axios from 'axios'; 
import '../Components/profile.css'  
import * as Yup from 'yup';
import '../Components/forms.css'

const baseUrl = process.env.REACT_APP_BASE_URL;

const loginSchema = Yup.object().shape({

  firstName: Yup.string()
    .min(2, 'First name must be at least 2 characters')
    .max(20, 'First name must be at most 20 characters')
    .required('First name is required'),

  lastName: Yup.string()
    .min(2, 'Last name must be at least 2 characters')
    .max(20, 'Last name must be at most 20 characters')
    .required('Last name is required'),

  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
    
  phone: Yup.string()
    .required('Mobile number is required')
    .matches(/^\d+$/, 'Mobile number must contain only numbers')
    .min(10, 'Mobile number must be at least 10 digits long'),

  aboutMe: Yup.string()
  .nullable()
  .test(
    'about-me-length',
    'About Me must be at least 20 characters and at most 200 characters',
    (value) => !value || (value.length >= 20 && value.length <= 200)
  ),
  
});

const AccountDtls=(props)=> {

  const [errors, setErrors] = useState({});
  const [eConfBtn,setEconfBtn]=useState(false);
  const [mConfBtn,setMconfBtn]=useState(false);
  const [eLoading,setELoading]=useState(true);
  const [mLoading,setMLoading]=useState(true);
  const [eCount,setECount]=useState(1);
  const [mCount,setMCount]=useState(1);
  const [fire,setFire]=useState(false);
  const [mobile,setMobile]=useState(null);
  const [email,setEmail]=useState(null);
  
  const [accountDtls, setAccountDtls] = useState({  
    firstName:'', 
    lastName:'',
    email:'',
    phone:'',
    auth:'',
    aboutMe:'',
    eConfCode:'',
    mConfCode:'',
    eConfirmed:0,
    mConfirmed:0
  });
//-------------------------------------------------------------------------------------------  

  useEffect(()=>{

    const fetchUserDtls = async () => {
      try{
          const response = await axios.get(`${baseUrl}/get_user_dtls.php?userId=${props.userId}`)
                              
          if( response.data.id > 0 ){          
            setAccountDtls({
              firstName:response.data.name,
              lastName:response.data.fname,
              email:response.data.email,
              phone:response.data.phone,
              auth: response.data.auth,
              aboutMe:response.data.aboutMe,
              eConfirmed:response.data.eConfirmed,
              mConfirmed:response.data.mConfirmed 
            }); 

            setMobile(response.data.phone)
            setEmail(response.data.email)

            if(response.data.email===null)
              setELoading(false) 

            if(response.data.phone===null)
              setMLoading(false)         
          }
          else{                       
            setAccountDtls({
              firstName:'',
              lastName:'',
              email:'',
              phone:'',
              aboutMe:'',
              eConfirmed:false,
              eConfirmed:false
            });                         
          }                              
      } 
      catch (error) {
        props.alert(error.message,'ERROR') ;         
      }        
    }

    fetchUserDtls();
    
  },[]);
//-------------------------------------------------------------------------------------------

  const handleChange = e => {
      const { name, value } = e.target;
      setAccountDtls(prevState => ({
          ...prevState,
          [name]: value
      }));

      if (name === 'email') {
        
        if (value !== email || email === null) {
            setELoading(false);
        }
        else
          setELoading(true);
      }

      if (name === 'phone') {
        
        if (value !== mobile || mobile === null) {
            setMLoading(false);
        }
        else
          setMLoading(true);
      }

  };              
//-------------------------------------------------------------------------------------------  

  const handleSubmit = async (event) => {
                                    
    event.preventDefault();
        
    const buttonName = event.nativeEvent.submitter.name;
    
    switch(buttonName){
      case "submit" : try {
                            await loginSchema.validate(accountDtls, { abortEarly: false }); 
                            update()
                            setEmail(accountDtls.email) 
                            setMobile(accountDtls.phone)
                            setErrors({})
                          } catch (err) {
      
                            setErrors(err.inner.reduce((acc, { path, message }) => {
                              acc[path] = message;
                              return acc;
                            }, {}));
                          }                                
                      break;

      case "v1"     : getEconfCode()
                      break;

      case "v2"     : getMconfCode()
                      break;
      case "eCode"  : sendEconfCode()
                      break;
      case "mCode"  : sendMconfCode()
                      break;
    }            
  }  
//------------------------------------------------------------------------------------------- 

  const update = async () => {

  try{

      const data = await updateUserDtls(
        accountDtls.firstName, 
        accountDtls.lastName,
        accountDtls.email,
        accountDtls.phone,
        accountDtls.aboutMe, 
        props.userId,                        
      )

      if(data.status==='SUCCESS'){
        setAccountDtls(prevState => ({
          ...prevState,
          eConfirmed: data.eConf,
          mConfirmed: data.mConf
        }))
      }

      setELoading(true)
      setMLoading(true)

      props.alert(data.message,data.status)
      props.refreshName(accountDtls.firstName+' '+accountDtls.lastName)

  } catch(error)  {
    props.alert(error.message,'ERROR')
  }              
  
}
//------------------------------------------------------------------------------------------- 

  const getEconfCode = async () => {

    try {
        setFire(true)
        const response = await axios.get(`${baseUrl}/gen_email_verif_code.php?userId=${props.userId}`);
        
        const responseData = response.data;

        if (responseData.code > 0) {
            setEconfBtn(true);
            props.alert(responseData.message,'SUCCESS'); 
        } else {
            throw new Error(responseData.message);
        }                              
    } catch (error) {
        props.alert(error.message,'SUCCESS');
    } finally {
      setFire(false)
    }
  }
//------------------------------------------------------------------------------------------- 
  
  const sendEconfCode = async () => {
    try {
      const response = await axios.get(`${baseUrl}/email_verification.php`, {
        params: {
          userId: props.userId,
          code: accountDtls.eConfCode
        }
      });

      const responseData = response.data;

      if (responseData.code > 0) {
        setAccountDtls(prevState => ({
          ...prevState,
          eConfirmed: true
        }));

        props.alert(responseData.message,'SUCCESS');
        setEconfBtn(false);
      } else if (responseData.code === 0) {
        if (eCount < 2) {
          props.alert(`נסיון האימות נכשל, נסה שנית!! נשארו לך עוד ${2 - eCount} נסיונות`,'ERROR');
          setECount(count => count + 1);
        } else {
          props.alert("נסיון האימות נכשל!! לא נשאר לך עוד נסיונות",'ERROR');
          setECount(1);
          setEconfBtn(false);
        }

        setAccountDtls(prev => ({ ...prev, ['eConfCode']: '' }));
      } else {
        
        throw new Error(responseData.message);
      }
    } catch (error) {
      props.alert(error.message,'ERROR'); 
    }
  }
//------------------------------------------------------------------------------------------- 

  const getMconfCode = async () => {
    try {
        setFire(true);
        const response = await axios.get(`${baseUrl}/gen_mobile_verif_code.php?userId=${props.userId}`);
        
        const responseData = response.data;

        if (responseData.code > 0) {
            setMconfBtn(true);
            props.alert(responseData.message,'SUCCESS'); 
        } else {
            throw new Error(responseData.message);
        }                              
    } catch (error) {
      props.alert(error.message,'ERROR'); 
    } finally {
        setFire(false); 
    }
  }
//------------------------------------------------------------------------------------------- 

  const sendMconfCode = async () => {
    try {
      const response = await axios.get(`${baseUrl}/mobile_verification.php`, {
        params: {
          userId: props.userId,
          code: accountDtls.mConfCode
        }
      });

      const responseData = response.data;

      if (responseData.code > 0) {
        setAccountDtls(prevState => ({
          ...prevState,
          mConfirmed: true
        }));

        props.alert(responseData.message,'SUCCESS');
        setMconfBtn(false);        
      } else if (responseData.code === 0) {
        if (mCount < 2) {
          props.alert(`נסיון האימות נכשל, נסה שנית!! נשארו לך עוד ${2 - mCount} נסיונות`,'ERROR');
          setMCount(count => count + 1);
        } else {
          props.alert("נסיון האימות נכשל!! לא נשאר לך עוד נסיונות",'ERROR');
          setMCount(1);
          setMconfBtn(false);
        }

        setAccountDtls(prev => ({ ...prev, ['mConfCode']: '' }));
      } else {
        throw new Error(responseData.message);
      }                              
    } catch (error) {
      props.alert(error.message,'ERROR'); 
    }
  }
//------------------------------------------------------------------------------------------- 

  return (
    
    <form onSubmit={handleSubmit}>
      
      <div className="position-relative d-flex flex-column profile-gap p-4 pt-5">

        { fire ?
            <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"300",left:'300'}}>
                <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
            </div>
            :
            null
        }    

        <div className="d-flex gap-3">

            <div  className="label-100-wrap">                
              <label className="form-label label-100" for="name">שם פרטי</label>
              <div>
                <input 
                  type="text" 
                  className={`form-control ${errors.firstName? 'invalid' : ''}`}
                  name="firstName" 
                  value={accountDtls.firstName} 
                  onChange={handleChange} 
                  placeholder="שם פרטי"
                />
               {errors.firstName && <p className="error">{errors.firstName}</p>}
              </div>   
            </div>  

            <div  className="label-100-wrap">                
              <label className="form-label label-100" for="fname">שם משפחה</label>
              <div>
                <input 
                  type="text" 
                  className={`form-control ${errors.lastName? 'invalid' : ''}`} 
                  name="lastName" 
                  value={accountDtls.lastName} 
                  onChange={handleChange} 
                  placeholder="שם משפחה" 
                /> 
                {errors.lastName && <p className="error">{errors.lastName}</p>}
              </div>   
            </div>

        </div>

        <div className="d-flex gap-3 align-items-center">

            <div className="d-flex gap-2 align-items-center">

              <div  className="label-100-wrap">                
                <label className="form-label label-100" for="email">דואר אלקטרוני</label>
                <div>
                  <input  
                    type="text" 
                    className={`form-control ${errors.email? 'invalid' : ''}`} 
                    name="email" 
                    value={accountDtls.email} 
                    onChange={handleChange} 
                    placeholder="דואר אלקטרוני"
                    disabled={accountDtls.auth==='GOOGLE'}
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>   
              </div>

              {eLoading && !errors.email?
                <div>
                  {accountDtls.eConfirmed==1?
                    <FcApproval size={22}/>
                    :
                    <MdCancel color="red" size={22}/>}
                </div>
                :
                null
              }

            </div>

            {accountDtls.eConfirmed==0 && eLoading && !errors.email?
              <div className="d-flex gap-3">

                {!eConfBtn?
                  <div>
                    <button name="v1" class="btn btn-outline-secondary btn-sm" style={{width:"60px",height:"30px"}}>
                      אימות
                    </button>
                  </div>  
                  :
                  <>
                    <div  className="label-100-wrap">                
                      <label className="form-label label-100" for="eConfCode">קוד אימות</label>
                      <div dir="rtl">
                          <input type="text" class="form-control" id="eConfCode" name="eConfCode" value={accountDtls.eConfCode} onChange={handleChange} style={{width:"80px",height:"30px"}} required></input>                    
                      </div>   
                    </div>

                    <div>
                      <button name="eCode" class="btn btn-outline-secondary btn-sm" style={{width:"60px",height:"30px"}}>
                        שלח
                      </button>
                    </div>
                  </>
                }

              </div>
              :
              null
            }
        </div>

        <div className="d-flex gap-3 align-items-center">

            <div className="d-flex gap-2 align-items-center">

              <div  className="label-100-wrap">                
                <label className="form-label label-100" for="phone">טלפון נייד</label>
                <div>
                  <input  
                    type="text" 
                    className={`form-control ${errors.phone? 'invalid' : ''}`} 
                    name="phone" 
                    value={accountDtls.phone} 
                    onChange={handleChange} 
                    placeholder="טלפון נייד"
                  />
                  {errors.phone && <p className="error">{errors.phone}</p>}
                </div>   
              </div>
              
              {mLoading && !errors.phone?
                <div>
                  {accountDtls.mConfirmed==1?
                    <FcApproval size={22}/>
                    :
                    <MdCancel color="red" size={22}/>}
                </div>
                :
                null
              }

            </div>

            {accountDtls.mConfirmed==0 && mLoading && !errors.phone?
              <div className="d-flex gap-3">

                {!mConfBtn?
                  <div>
                    <button name="v2" class="btn btn-outline-secondary btn-sm" style={{width:"60px",height:"30px"}}>
                      אימות
                    </button>
                  </div>  
                  :
                  <>
                    <div  className="label-100-wrap">                
                      <label className="form-label label-100" for="mConfCode">קוד אימות</label>
                      <div dir="rtl">
                          <input type="text" class="form-control" id="mConfCode" name="mConfCode" value={accountDtls.mConfCode} onChange={handleChange} style={{width:"80px",height:"30px"}} required></input>                    
                      </div>   
                    </div>

                    <div>
                      <button name="mCode" class="btn btn-outline-secondary btn-sm" style={{width:"60px",height:"30px"}}>
                        שלח
                      </button>
                    </div>
                  </>
                }

              </div>
              :
              null
            }
        </div>
        
        <div className="label-100-wrap">
          <label className="form-label label-100" htmlFor="about-me">על עצמי</label>
          <div dir="rtl">
            <textarea
              className={`form-control ${errors.aboutMe ? 'invalid' : ''}`}
              id="about-me"
              name="aboutMe"
              rows="4"
              value={accountDtls.aboutMe}
              onChange={handleChange}
            ></textarea>
            {errors.aboutMe && <p className="error">{errors.aboutMe}</p>}
          </div>
        </div>

        <div>
          {!eConfBtn&&!mConfBtn?
            <button type="submit" name="submit" class="btn btn-outline-secondary btn-sm daily-prog-btn" style={{width:"100%"}}>
              שמירה
            </button>
            :
            <button type="submit" class="btn btn-outline-secondary btn-sm daily-prog-btn" style={{width:"100%"}} disabled>
              שמירה
            </button>
          }
        </div>                   
                                                                                                                                                          
      </div> 
    </form>     
  );
}

export default AccountDtls;
