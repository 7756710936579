
import React, {useState,useEffect} from "react";
import axios from 'axios';
import {Modal} from 'react-bootstrap'; 
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CourseIcon from "./course_icon";
import { BsImages  } from 'react-icons/bs';
import Pagination from '@mui/material/Pagination';
import { Alert } from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import ReactLoading from 'react-loading';
import UploadPhotoFile from "./upload_photo";
import { newCanvaTemplate } from "./db";
import * as Yup from 'yup';

const baseUrl = process.env.REACT_APP_BASE_URL;
const win=8;


const GenerateSocialPost=(props)=>{ 
          
    const [show,setShow]=useState(false);    
    const [checked,setChecked]=useState(0);
    const [page, setPage] = useState(1);
    const [pics,setPics]=useState([]);
    const [fire,setFire]=useState(false);

    const [alert, setAlert] = useState({
      msg: null,
      status: 'SUCCESS',
    });
   
    useEffect(()=>{

      const fetchPhotos = async () => {
        try {
            const response = await axios.get(`${baseUrl}/canva_templates.php`);
            
            // Check if the response status is "error" and throw an exception
            if (response.data.status === 'error') {
                throw new Error(response.data.message || 'An error occurred while fetching templates.');
            }
    
            // Check if the response is successful and contains templates
            if (response.data.status === 'success' && Array.isArray(response.data.templates)) {
                setPics(response.data.templates);
            } else {
                setPics([]);
            }
        } catch (error) {
            set_alert(error.message, 'ERROR');
        }
      };
    
      openCanvaTemplatesModal();
      fetchPhotos();
                       
    },[]);
//-------------------------------------------------------------------------------------------

  const set_alert = (msg,status) =>{
    setAlert({
      msg:msg,
      status:status,
    })
  }
//-------------------------------------------------------------------------------------------

    const openCanvaTemplatesModal=()=>{ 
      setShow(true)
    };
//-------------------------------------------------------------------------------------------

    const closeCanvaTemplatesModal=()=>{ 
      setShow(false); 
      props.resetMenu(0);
    };
//-------------------------------------------------------------------------------------------    

    const getValueFromPic=(value)=>{
      setChecked(value);
    }
//-------------------------------------------------------------------------------------------

    const handleChange = (_,page)=> {
      
      if (page !== null) 
        setPage(page);     
    }
//-------------------------------------------------------------------------------------------

    return (  
      <>        
        <Modal 
            show={show}  
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"            
        >  
            <Modal.Header style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6', padding: '10px 15px',height: '60px' }}>
              <div className="d-flex gap-2 align-items-center">
                <div><BsImages size={30} /></div>
                <div className="mt-1"><h4>Add Canva Templates</h4></div>
              </div>
                <button
                    type="button"
                    className="custom-modal-close"
                    aria-label="Close"
                    onClick={closeCanvaTemplatesModal}
                >
                    &times;  {/* Close "X" icon */} 
                </button>             
            </Modal.Header>

            <Modal.Body>
              <div className="container position-relative">

                {fire?
                  <div className="position-absolute top-50 start-50 translate-middle-x" style={{zIndex: "1",top:"300",left:'300'}}>
                      <ReactLoading type="spin" color="rgb(53, 126, 221)" height={70} width={70}/>
                  </div>
                  :
                  null
                } 

              
                {alert.msg != null ? (
                  <div className="container mb-3" dir="ltr">
                    <Alert
                      severity={alert.status.toLowerCase()} 
                      variant="outlined"
                      onClose={() => {
                        setAlert({ msg: null, status: 'SUCCESS' }); 
                      }}
                    >
                      <AlertTitle>
                        <span className="bold-text">
                          {alert.status === 'SUCCESS' ? 'Success' : 'Error'}
                        </span>
                      </AlertTitle>
                      <span className="bold-text">{alert.msg}</span>
                    </Alert>
                  </div>
                ) : null} 
                
                <div className="row justify-content-center gap-2" style={{ minHeight: '100%' }}>
                  
                  <div className="col-lg-12 border rounded" style={{borderColor:"#d3d3d3"}}>

                    <div className="d-flex flex-wrap gap-1 mt-2" style={{ minHeight: 210, height: 'auto' }}>
                      {
                        pics.slice((page-1)*win,(page-1)*win+win).map((pic)=>(                  
                          <CourseIcon 
                            value={pic.Id} 
                            checked={checked} 
                            icon={pic.template_url} 
                            getValueFromIcon={getValueFromPic}
                            alert={set_alert}
                          />
                      ))}                               
                    </div>
                                                                         
                    <div className="mt-3 mb-2 d-flex justify-content-between align-items-center" dir="ltr">

                      <Pagination 
                        count={Math.ceil(pics.length / win)} 
                        variant="outlined" 
                        shape="rounded" 
                        color="primary" 
                        page={page} 
                        onChange={handleChange} 
                      />

                    </div>                    
                  </div>
                </div>
              </div>  
            </Modal.Body>

            <Modal.Footer>  
              <div className="d-flex gap-2 me-3"> 

                <Button 
                  variant="contained" 
                  color="error"   
                  size="small" 
                  onClick={closeCanvaTemplatesModal}
                >
                  סגור
                </Button> 

              </div>  
            </Modal.Footer>
              
        </Modal> 
      </>        
    )  
  } 
  
export default GenerateSocialPost;  
