import React, { useState, useEffect } from "react";
import { SiFacebook } from "react-icons/si";
import './social_btns.css';
import { login, signup } from "./db";

const FacebookBtn = (props) => {
    const { mode, user, turnFireOn, turnFireOff, connect, alert, disabled } = props;
    const [authCode, setAuthCode] = useState(null);

    const setItemWithExpiration = (key,data) => {
      const now = new Date()
      
      const currentTime= now.getTime();
  
      const item = {
        data: data,
        startActivity: currentTime, 
        lastActivity : currentTime,
      }
      
      window.localStorage.setItem(key, JSON.stringify(item));
    }
//-------------------------------------------------------------------------------------------

  const handleFacebookLogin = () => {
    const clientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
    const redirectUri = encodeURIComponent("https://www.doroosonline.co.il/api/facebook-redirect.php");
    const scope = "public_profile,email";

    const oauthUrl = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    // Open OAuth dialog in a maximized popup window
    const popupWindow = window.open(
        oauthUrl,
        '_popup',
        `width=${screenWidth}, height=${screenHeight}, left=0, top=0`
    );

    if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === "undefined") {
        alert("Popup blocked! Please allow popups for this site.", 'ERROR');
        return;
    }
  };

//-------------------------------------------------------------------------------------------

  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
  }; 
//------------------------------------------------------------------------------------------- 

  const setCookie = (name, value, minutes) => {
    let expires = "";
    if (minutes) {
        let date = new Date();
        date.setTime(date.getTime() + minutes * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = `${name}=${value}${expires}; path=/; domain=.doroosonline.co.il; Secure; SameSite=Lax`;
  };
//-------------------------------------------------------------------------------------------

  useEffect(() => {
    const checkCookie = () => {
      const newCode = getCookie("facebook_auth_code");
      const oldCode = getCookie("last_facebook_auth_code"); // Retrieve the last stored code

      if (newCode && newCode !== oldCode) { // Compare new code with old code
        
        
        // Call the callback function with the retrieved code
        handleCallback(newCode);

        // Update state to prevent duplicate handling
        setAuthCode(newCode);

        // Store the new code in a cookie for future comparison
        setCookie("last_facebook_auth_code", newCode, 30); // Stores for 30 minutes
      }
    };

    // Check for cookie changes every second
    const interval = setInterval(checkCookie, 1000);

    // Cleanup when component unmounts
    return () => clearInterval(interval); 

  }, [authCode]);

//-------------------------------------------------------------------------------------------

  const handleCallback = async (code) => {
    if (!code) {
        alert("Authorization code not found.", "ERROR");
        return;
    }

    turnFireOn(); // Show loading indicator

    try {
        const response = await fetch(`https://www.doroosonline.co.il/api/facebook-callback.php?code=${code}`);
        const data = await response.json();

        if (!data.success) {
            throw new Error(data.error || "Facebook login failed.");
        }

        const { f_id, name, email, picture } = data.user;
        const auth = "FACEBOOK";

        // Split name into first and last name
        const [f_name, ...l_nameArray] = name.split(" ");
        const l_name = l_nameArray.join(" ");

        // Handle authentication (login or signup)
        let authResponse;
        if (mode === 'login') {
            authResponse = await login(user, auth, email, f_id);
        } else {
            authResponse = await signup(user, auth, email, f_id, f_name, l_name);
        }

        const [id, userId, full_name, photo, enabled, blocked] = authResponse;

        if (id > 0 && userId > 0) {
            const loginData = {
                isLoggedIn: true,
                userID: userId,
                id: id,
                name: full_name,
                email: email,
                picture: photo !== null ? photo : picture,
                auth: auth,
                enabled: enabled,
                blocked: blocked,
                user: user,
            };

            setItemWithExpiration("loginData", loginData);
            connect(); // Notify parent component of successful login
        } else {
            throw new Error("Login failed. Please try again.");
        }
    } catch (error) {
        alert(error.message, "ERROR");
    } finally {
        turnFireOff(); // Hide loading indicator
    }
  };
//-------------------------------------------------------------------------------------------

  return (
      <div>
          <button
              className="fb-login-button social-btn"
              onClick={handleFacebookLogin}
              disabled={disabled}
          >
              <div className="d-flex align-items-center gap-3 ps-2">
                  <span>
                      <SiFacebook className="icon" />
                  </span>
                  <span>התחבר באמצעות פייסבוק</span>
              </div>
          </button>
      </div>
  );
};

export default FacebookBtn;
