import React, { useState, useEffect } from "react";
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
import axios from 'axios';
import { login, signup } from "./db"; 
import './social_btns.css';

const GoogleBtn = (props) => {
  const [user, setUser] = useState(null); 
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
          setProfile(res.data);
        })
        .catch((error) => props.alert(error.message,'ERROR'));
    }
  }, [user]);
//-------------------------------------------------------------------------------------------

  useEffect(() => {
    if (profile) {
      handleAuth();
    }
  }, [profile]);
//-------------------------------------------------------------------------------------------

  const setItemWithExpiration = (key, data) => {
    const now = new Date();
    const currentTime = now.getTime();

    const item = {
      data: data,
      startActivity: currentTime,
      lastActivity: currentTime,
    };

    window.localStorage.setItem(key, JSON.stringify(item));
  };
//-------------------------------------------------------------------------------------------

  const googleLogin = useGoogleLogin({
    
    onSuccess: (codeResponse) => {
      //props.set_show_overlay(false); // Hide overlay after success
      setUser(codeResponse);
    },
    onError: (error) => {
      //props.set_show_overlay(false); // Hide overlay after error
      props.alert(`Login Failed: ${error.message}`, 'ERROR');
    }
  });
//-------------------------------------------------------------------------------------------

  const handleLogin = () => {
    //props.set_show_overlay(true); // Show overlay before login starts
    googleLogin(); // Trigger login
  };
//-------------------------------------------------------------------------------------------

  const handleAuth = async () => {
    
    const googleId = profile.id;
    const name = profile.name;
    const imageUrl = profile.picture;
    const email = profile.email;
    const auth = "GOOGLE";
    const user = props.user;

    const [f_name, ...l_nameArray] = name.split(" ");
    const l_name = l_nameArray.join(" ");

    try {
      props.turnFireOn();
      let authResponse;
     
      if (props.mode === 'login') {
        authResponse = await login(user, auth, email, googleId);
      } else {
        authResponse = await signup(user, auth, email, googleId, f_name, l_name);
      }

      const [id, userId, full_name, photo, enabled, blocked] = authResponse;  

      if (id > 0 && userId > 0) { 
        const loginData = {
          isLoggedIn: true, 
          userID: userId,
          id: id,
          name: full_name,
          email: email,
          picture: photo !== null ? photo : imageUrl,
          auth: auth,
          enabled: enabled,
          blocked: blocked,
          user: user,
        };

        setItemWithExpiration('loginData', loginData);
        props.connect();
      } else {
        throw new Error("Authentication failed. Please try again.");
      }
    } catch (error) {
      props.alert(error.message,'ERROR');
    } finally {
      props.turnFireOff();
    }
  };
//-------------------------------------------------------------------------------------------

  return (
    <button
      onClick={handleLogin}
      className="google-login-button social-btn"
      disabled={props.disabled}
    >
      <div className="d-flex align-items-center gap-3 ps-2">
        <span>
          <FcGoogle className="icon" />
        </span>
        <span>התחבר באמצעות גוגל</span>                       
      </div>              
    </button>
  );
};

export default GoogleBtn;
